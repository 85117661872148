// Import dependencies
import React from "react";
import { getFirebase } from "../api/firebase";
import {connect} from "react-redux";

//Form.js
class SendGridTest extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickStripe = this.handleClickStripe.bind(this);
  }

  handleClickStripe() {

    const app = import("firebase/app");
    const auth = import("firebase/auth");
    const firestore = import("firebase/firestore");

    Promise.all([app, auth, firestore]).then(([firebase]) => {


              let emailInclude = false;
              getFirebase(firebase)
                .firestore().collection("refeal_codes").get().then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    if (doc.data().email == "iliass.zeggani@gmail.com") {
                      emailInclude = true;
                    }
                  });
              });

              console.log(emailInclude);
              if (!emailInclude) {
                let refeal_code = "reinin-" + "iliass Zeggani".replace(/ /g, "").toLowerCase().substring(0, 4);
                let uniqueRefealCode = true;

                while (uniqueRefealCode) {
                  uniqueRefealCode = false;
                  refeal_code += Math.floor(Math.random() * 10000);
                  refeal_code += String.fromCharCode(97+Math.floor(Math.random() * 26));
                  refeal_code += String.fromCharCode(97+Math.floor(Math.random() * 26));
                  getFirebase(firebase)
                    .firestore().collection("refeal_codes").get().then((querySnapshot) => {
                      querySnapshot.forEach((doc) => {
                        if (doc.data().code == refeal_code) {
                          uniqueRefealCode = true;
                        }
                      });
                  });
                }

/*
                getFirebase(firebase)
                  .firestore().collection("refeal_codes").add({
                    code: refeal_code,
                    email: this.props.email
                });
*/
              }

    });

  }

  render() {
    return (
      <div>
      <button onClick={this.handleClickStripe}>handleClickStripe</button>
      </div>
    );
  }
}

export default SendGridTest;
